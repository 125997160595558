<template>
  <div class="roomCupBox">
    <header class="bannerBox">
      <div class="ruleBtn">
        <span @click="changePop(1)">{{ $t("roomcup_rule_icon") }}</span>
      </div>
      <div class="roomIdBox">
        <p>{{ $t("roomcup_Room ID ") }}</p>
        <p>{{ actInfo.roomId == 0 ? '--' : actInfo.roomId }}</p>
      </div>
    </header>
    <div class="bodyBox">
       <p class="bodyHead">{{ $t("roomcup_countdown ") }}</p>
       <!-- <div class="downTimeBox" v-if="$i18n.locale == 'ar'">
          <div>
            <div>
              <span>{{ days[1] }}</span>
              <span>{{ days[0] }}</span>
            </div>
            <p>{{ $t('roomcup_day') }}</p>
          </div>
          <div>
            <div>
              <span>{{ hours[1] }}</span>
              <span>{{ hours[0] }}</span>
            </div>
            <p>{{ $t('roomcup_hour') }}</p>
          </div>
          <div>
            <div>
              <span>{{ minutes[1] }}</span>
              <span>{{ minutes[0] }}</span>
            </div>
            <p>{{ $t('roomcup_minutes ') }}</p>
          </div>
          <div>
            <div>
              <span>{{ seconds[1] }}</span>
              <span>{{ seconds[0] }}</span>
            </div>
            <p>{{ $t('roomcup_seconds ') }}</p>
          </div>
        </div> -->
        <div class="downTimeBox">
          <div>
            <div>
              <span>{{ days[0] }}</span>
              <span>{{ days[1] }}</span>
            </div>
            <p>{{ $t('roomcup_day') }}</p>
          </div>
          <div>
            <div>
              <span>{{ hours[0] }}</span>
              <span>{{ hours[1] }}</span>
            </div>
            <p>{{ $t('roomcup_hour') }}</p>
          </div>
          <div>
            <div>
              <span>{{ minutes[0] }}</span>
              <span>{{ minutes[1] }}</span>
            </div>
            <p>{{ $t('roomcup_minutes ') }}</p>
          </div>
          <div>
            <div>
              <span>{{ seconds[0] }}</span>
              <span>{{ seconds[1] }}</span>
            </div>
            <p>{{ $t('roomcup_seconds ') }}</p>
          </div>
        </div>
        <div>
          <template v-if="actInfo.regionState == 1">
            <div class="weekGift" v-if="typeShow == 1 || typeShow == 2">
              <p class="weekHead">{{ $t("roomcup_coin_recieve") }}</p>
              <p class="moneyNum">{{ actInfo.progress.toLocaleString('en-US') }}</p>
              <div class="progressBox">
                <div class="top">
                  <span :style="`opacity:${actInfo.pocket1 == 0 ? '0' : 1}`">{{ actInfo.pocket1.toLocaleString('en-US') }}</span>
                  <span>{{ $t("roomcup_target_icon") }}</span>
                  <span>{{ actInfo.pocket2.toLocaleString('en-US') }}</span>
                </div>
                <div class="center">
                  <div class="progressBg">
                    <div class="progressContent" :style="`width:${(actInfo.progress - actInfo.pocket1) / (actInfo.pocket2 - actInfo.pocket1) * 100}%`"></div>
                  </div>
                  <!-- <i></i> -->
                  <i :style="`${$i18n.locale == 'ar' ? 'right' : 'left'}:${countLeftPosition * 100}%`"></i>
                  <!-- <i></i> -->
                </div>
                <div class="bottom">
                  <span :style="`opacity:${actInfo.diamond1 == 0 ? '0' : 1}`">{{ actInfo.diamond1.toLocaleString('en-US') }}</span>
                  <span>{{ $t("roomcup_reward_icon") }}</span>
                  <span>{{ actInfo.diamond2.toLocaleString('en-US') }}</span>
                </div>
              </div>
            </div>
            <div class="roomRewardsBox" v-if="typeShow == 1">
              <p class="rewardHead">{{ $t("roomcup_roomreward") }}</p>
              <div class="targetRewardsBox">
                <div class="targetRewards">
                  <span>{{ $t("roomcup_roomreward_lasttarget") }}</span>
                  <span v-if="actInfo.lastWeekState != 0">{{ actInfo.lastWeekPt.toLocaleString('en-US') }} {{ $t("roomcup_coins") }}</span>
                  <span v-else>{{ $t('roomcup_notachieved') }}</span>
                </div>
                <div class="targetRewards">
                  <span>{{ $t("roomcup_roomreward_lastreward") }}</span>
                  <span v-if="actInfo.lastWeekState != 0">{{ actInfo.lastWeekRwd.toLocaleString('en-US')}} {{ $t("roomcup_diamonds") }}</span>
                  <span v-else>{{ $t('roomcup_notachieved') }}</span>
                </div>
              </div>
              <div class="receiveBtn" v-if="actInfo.lastWeekState == 1" @click="getReceive(1)">{{ $t("roomcup_claim_icon") }}</div>
              <div class="receiveBtn disClick" v-else>{{ actInfo.doubleState == 2 ? $t("roomcup_claimed_icon") : $t("roomcup_claim_icon") }}</div>
            </div>
            <!-- <div class="roomRewardsBox dubleTargetBox" v-if="typeShow == 1">
              <p class="rewardHead">{{ $t("roomcup_doubletargetcontest") }}</p>
              <p class="proportion">{{ $t("roomcup_ratio") }} {{actInfo.doubleRadio / 100}}%</p>
              <div class="targetRewardsBox">
                <div class="targetRewards">
                  <span>{{ $t("roomcup_doubletarget") }}</span>
                  <span v-if="actInfo.lastWeekState != 0">{{ actInfo.doubleWeekPt.toLocaleString('en-US') }} {{ $t("roomcup_coins") }}</span>
                  <span v-else>{{ $t('roomcup_notachieved') }}</span>
                </div>
                <div class="targetRewards">
                  <span>{{ $t("roomcup_doubletarget_reward") }}</span>
                  <span v-if="actInfo.lastWeekState != 0">{{ actInfo.doubleRwd.toLocaleString('en-US') }} {{ $t("roomcup_diamonds") }}</span>
                  <span v-else>{{ $t('roomcup_notachieved') }}</span>
                </div>
              </div>
              <div class="receiveBtn" v-if="actInfo.doubleState == 1" @click="getReceive(2)">{{ $t("roomcup_claim_icon") }}</div>
              <div class="receiveBtn disClick" v-else>{{ actInfo.doubleState == 2 ? $t("roomcup_claimed_icon") : $t("roomcup_claim_icon") }}</div>
            </div> -->
            <div class="nullBox" v-if="typeShow == 3">
              <div class="nullBody">
                <div class="nullImg"></div>
                <!-- 您还没有创建过房间 -->
                <p>{{ $t("roomcup_noroom") }}</p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="nullBox">
              <div class="nullBody">
                <div class="nullImg"></div>
                <!-- 该地区不参与活动 -->
                <p>{{ $t("roomcup_noarea") }}</p>
              </div>
            </div>
          </template>
          
        </div>
    </div> 
    <!-- 规则 -->
    <div class="popBox" v-if="popFlag == 1">
      <div class="popBody">
        <span class="closeBtn" @click="changePop(0)"></span>
          <div class="rewardHead">{{ $t("roomcup_rule_icon") }}</div>
        <p>{{ $t("roomcup_rule_row1") }}</p>
        <p>{{ $t("roomcup_rule_row2") }}</p>
        <!-- <p>{{ $t("roomcup_rule_row3") }}</p> -->
        <p>{{ $t("roomcup_rule_row4") }}</p>
      </div>
    </div>

  </div>
 
</template>

<script>
import { Toast } from "vant";
export default {
  name: "roomcup",
  data() {
    return{
      popFlag: false,
      days:[0,0],
      hours:[0,0],
      minutes:[0,0],
      seconds:[0,0],
      // 活动信息
      actInfo:{},
      // 1是房间 2非房间
      type:1,
      // 展示信息类型  1是展示全部信息 2是自己没有房间从语音房进入  3是没有房间从非语音房进入
      typeShow: 1
    }
  },
  created() { 
    this.type = this.$route.query.type || 2;
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // 判断平台
    this.judgeClient()

    // this.getUserInfo() 
  },
  computed:{
    countLeftPosition(){
      let {pocket1,pocket2,progress} = this.actInfo;
      let left = (progress - pocket1) / (pocket2 - pocket1);
      // if(left == 0 || isNaN(left)){
      //   left = 0.035
      // }else if(left == 100){
      //   left = 0.96
      // }
      return left
    }
  },
  methods: {
    // 领取奖励
    getReceive(type){
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/roomcuptr/receive?token=" +
          this.token +
          "&uid=" + 
          this.uid+
          "&type=" + type,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
            if(type == 1){
              Toast.success(this.$t('roomcup_successful'));
              this.actInfo.lastWeekState = 2;
            }else{
              Toast.success(this.$t('roomcup_successful'));
              this.actInfo.doubleState = 2;
            }
        }else{
          Toast(data.message);
        }
      });
    },
    changePop(type){
      this.popFlag = type;
      if(type){
        this.stopScroll()
      }else{
        this.canScroll();
      }
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang, 
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
    // this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt6TWpJNCIsInh5eiI6IlEwWkRRakJCTURBdFJEYzJOaTAwTURZMkxUaEJRamd0UkVaR01rVXhSRFpCUWpjMSIsImV4cCI6MTk5MjkzODIyNn0.-8-fxCL-8HULEkCIUdI9AaLZEBYdDwi9ZHC3cjhxa5Y';
    //   this.uid = '793228'; 
    //   this.headers = {
    //       fid: '1001',
    //       os: 1,
    //       lang: 'zh-CN',
    //       version: '3.0',
    //       deviceid: 'CFCB0A00-D766-4066-8AB8-DFF2E1D6AB75',
    //   }
    //   let langs = 'en'
    //   this.langs = 'en'
    //   let lang = 'zh_CN'
      document.title = this.$t('roomcup_title')
      if (langs == "ar") {
          this.$i18n.locale = "ar";
      } else if(langs == "tr") {
          this.$i18n.locale = "tr";
      } else {
          this.$i18n.locale = "en";
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      this.getActInfo()
    },
    // 获取活动信息
    getActInfo(){
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/roomcuptr/info?token=" +
          this.token +
          "&type=" + this.type +
          "&uid=" +
          this.uid,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
            this.actInfo = data.data;
            // 展示信息类型  1是展示全部信息 2是自己没有房间从语音房进入  3是没有房间从非语音房进入
            if(this.type == 1){
              this.typeShow = this.actInfo.owner == 1 ? 1 : 2;
            } else {
              this.typeShow = this.actInfo.hadRoom == 1 ? 1 : 3;
            }
            this.finalsDownTime(this.actInfo.leaveTime * 1000);
        }else{
          Toast(data.message);
        }
      });
    },
    // 倒计时
    finalsDownTime(endTime) {
      this.timer = setInterval( ()=> {
        if (endTime < 0) {
          clearInterval(this.timer);
          return;
        }
        const days = this.zeroFill(Math.floor(endTime / (1000 * 60 * 60 * 24)));
        const hours = this.zeroFill(Math.floor((endTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = this.zeroFill(Math.floor((endTime % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((endTime % (1000 * 60)) / 1000));
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
        endTime = endTime - 1000;
      }, 1000);
    },
    zeroFill(num){
      if(num < 10){
        return [0,num]
      }else{
        return [num.toString().slice(0,1),num.toString().slice(1)]
      }
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    
  }
};
</script>

<style scoped>
@import './index.css';
</style>
